import React, { useEffect, useState } from "react";
import "./freelance.css";
import Header from "./Header";
import Loader from "react-js-loader";
import toast, { Toaster } from "react-hot-toast";
import useAxiosPublic from "./Hooks/useAxiosPublic";


const Freelance = () => {
  const axiousPublic = useAxiosPublic();

  const [randomName, setRandomName] = useState("");
  const [randomLastName, setRandomLastName] = useState("");
  const [randomUserName, setRandomUserName] = useState("");
  const [randomEmail, setRandomEmail] = useState("");
  const [randomCountryNames, setRandomCountryNames] = useState("");
  const [randomPasswords, setPasswords] = useState("");
  const [randomInterestedRole, setInterestedRole] = useState("");
  const [randomInterestedLocation, setInterestedLocation] = useState("");
  const [randomTitle, setTitle] = useState("");

  const [randomCurrentSalaryCtc, setCurrentSalaryCtc] = useState("");
  const [randomFixedSalaryCtc, setFixedSalaryCtc] = useState("");
  const [randomExpectedSalaryCtc, setExpectedSalaryCtc] = useState("");
  const [randomMuchExpectedSalaryCtc, setMuchExpectedSalaryCtc] = useState("");
  const [randomStartedPeriod, setStartedPeriod] = useState("");
  const [randomLongPeriod, setLongPeriod] = useState("");
  const [randomNegotiablePeriod, setNegotiablePeriod] = useState("");
  // const [randomOkaytoRemoteJob, setOkaytoRemoteJob] = useState("");
  // const [randomCitiesNcr, setCitiesNcr] = useState("");
  // const [randomDesireCitiesWorking, setDesireCitiesWorking] = useState("");

  const [randomFullTimeJobExperience, setFullTimeJobExperience] = useState("");
  const [randomReportPeople, setReportPeople] = useState("");
  const [randomTopSkill, setTopSkill] = useState("");

  // const [randomDescribeJobIda, setDescribeJobIda] = useState("");

  const [randomHearAboutUs, setHearAboutUs] = useState("");
  const [randomReadyToInterview, setReadyToInterview] = useState("");
  const [randomJobSearch, setJobSearch] = useState("");
  const [randomGitHub, setGitHub] = useState("");
  const [randomHourlyRateFrom, setHourlyRateFrom] = useState("");
  const [randomHourlyRateTo, setHourlyRateTo] = useState("");

  // verify email
  const [verificationResult, setVerificationResult] = useState(null);

  // checkbox
  const [isChecked, setIsChecked] = useState(false);

  // dynamic data ke lie
  const [data, setData] = useState({
    // signup
    fname: "",
    lname: "",
    password: "",
    uname: "",
    email: "",
    country: "",


    interestedRoles: "",
    dateOfBirth: "",
    currentCtc: "",
    expectedCtc: "",
    expectedFixedCtc: "",
    fixedCtc: "",
    hourlyRateFrom: "",
    hourlyRateTo: "",
    negotiablePeriod: "",
    startPeriod: "",
    title: "",
    longPeriod: "",
    isFresher: "",
    currentCompanyName: "",
    currentDesignation: "",
    currentlyEmployed: "",
    employmentType: "",
    endDate: "",
    salary: "",
    skillsUsed: "",
    startDate: "",
    summary: "",
    workingSummaryDetails: "",
    addEducation: "",
    boardName: "",
    englishMarks: "",
    mathMarks: "",
    schoolMarks: "",
    schoolMedium: "",
    schoolPassingYear: "",
    fullTimeJobExperience: "",
    github: "",
    linkedIn: "",
    portfolio: "",
    reportPeople: "",
    skills: "",
    stackOverflow: "",
    hearAboutUs: "",
    jobSearch: "",
    readyToInterview: "",
    whyOurCompany: "",
    biggestAchievement: "",
    profilePicture: "",
  });

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };
  const RandomStringGenerator = () => {
    const [randomString, setRandomString] = useState("");
    useEffect(() => {
      const randomStr = generateRandomString(10); // Adjust the length as needed
      setRandomString(randomStr);
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts
  };

  const fixedEmail = "ngtmail101@gmail.com";
  const names = ["John", "Jane", "Bob", "Alice", "Sam"];
  const lastNames = ["alice", "dam", "sam", "kumar", "shri"];
  const userNames = ["Johna", "Janeb", "Bobc", "Aliced", "Same"];

  const randomText = generateRandomString(10);
  const joinedString = randomText + fixedEmail;
  console.log("joinedString", joinedString);
  const emails = joinedString;
  console.log("email", emails);

  const countryNames = ["india", "india", "india", "india", "india"];
  const passwords = ["123456Aa!", "123456Aa!", "123456Aa!", "123456Aa!", "123456Aa!"];

  const interestedrole = [
    '["front end developer","ios developer","management skills","game developer"]',
    '["front end developer", "full stack developer","game developer", "ios developer"]',
    '["full stack developer","management skills","game developer", "ios developer"]',
    '["full stack developer","game developer","management skills","ios developer"]',
    '["ios developer","management skills","full stack developer","front end developer"] ',
  ];

  const interestedlocation = [
    "Delhi/NCR",
    "Out of NCR",
    "Other",
    "Delhi/NCR",
    "Out of NCR",
  ];
  const title = ["NodeJs", "java Js", "php Js", "python Js", "django Js"];

  const currentsalaryCtc = [1, 2, 3, 4, 5];
  const fixedsalaryCtc = [ ];
  // const fixedsalaryCtc = ["2", "5", "7", "4", "9"];
  const expectedsalaryCtc = [3, 6, 4, 8, 1];
  const muchExpectedsalaryCtc = [ ];
  // const muchExpectedsalaryCtc = ["5", "7", "8", "6", "3"];
  const startedperiod = ["No", "No", "No", "No", "No"];
  // const startedperiod = ["No", "Yes", "No", "Yes", "No"];
  const longperiod = ["1 week", "2 months", "15 days", "2 months", "1 month"];
  const negotiableperiod = [
    "yes",
    "no",
    "no",
    "yes",
    "no",
  ];

  const fullTimeJobExperience = [
    "0-2 years",
    "2-4 years",
    "4-6 years",
    "6-8 years",
    "8-10 years",
  ];
  const reportPeople = [
    "Not managing a team",
    "Less than 10 people",
    "More than 10 people",
    "Not managing a team",
    "Less than 10 people",
  ];
  const skills = [
    '{"html":"Beginner","css":"Intermediate","react":"Advanced"}',
    '{"react":"Intermediate"}',
    '{"sql":"Advanced"}',
    '{"html":"Expert","css":"Expert"}',
    '{"sql":"Expert"}',
  ];

  const hearAboutUs = [
    "Word of Mouth",
    "Job Portal",
    "Advertisement",
    "Job Portal",
    "Advertisement",
  ];
  const readyToInterview = [
    "05 days",
    "10 days",
    "15 days",
    "30 days",
    "01 month",
  ];

  const jobSearch = [
    "Open to new opportunities",
    "variety of opportunities",
    "Open to new opportunities",
    "variety of opportunities",
    "Open to new opportunities",
  ];
  const github = [
    "https://www.linkedin.com",
    "https://www.linkedin.com",
    "https://www.linkedin.com",
    "https://www.linkedin.com",
    "https://www.linkedin.com",
  ];

  const HourlyRateFrom = ["5", "7", "8", "6", "3"];
  const HourlyRateTo = ["5", "7", "8", "6", "3"];

  const pickRandomName = () => {
    const randomIndex = Math.floor(
      Math.random() * names.length,
      lastNames.length,
      userNames.length,
      emails.length,
      countryNames.length,
      passwords.length,
      interestedrole.length,
      interestedlocation.length,
      title.length,
      currentsalaryCtc.length,
      fixedsalaryCtc.length,
      expectedsalaryCtc.length,
      muchExpectedsalaryCtc.length,
      startedperiod.length,
      longperiod.length,
      negotiableperiod.length,
      fullTimeJobExperience.length,
      reportPeople.length,
      skills.length,
       hearAboutUs.length,
      readyToInterview.length,
      jobSearch.length,
      github.length,
      HourlyRateFrom.length,
      HourlyRateTo.length
    );

    const randomName = names[randomIndex];
    setRandomName(randomName);

    const randomLastName = lastNames[randomIndex];
    setRandomLastName(randomLastName);

    const randomUserName = userNames[randomIndex];
    setRandomUserName(randomUserName);

    const randomEmail = emails;
    console.log("random email", randomEmail);
    setRandomEmail(randomEmail);

    const randomCountryNames = countryNames[randomIndex];
    setRandomCountryNames(randomCountryNames);

    const randomPasswords = passwords[randomIndex];
    setPasswords(randomPasswords);

    const randomInterestedRole = interestedrole[randomIndex];
    setInterestedRole(randomInterestedRole);

    const randomInterestedLocation = interestedlocation[randomIndex];
    setInterestedLocation(randomInterestedLocation);

    const randomTitle = title[randomIndex];
    setTitle(randomTitle);

    const randomCurrentSalaryCtc = currentsalaryCtc[randomIndex];
    setCurrentSalaryCtc(randomCurrentSalaryCtc);

    const randomFixedSalaryCtc = fixedsalaryCtc[randomIndex];
    setFixedSalaryCtc(randomFixedSalaryCtc);

    const randomExpectedSalaryCtc = expectedsalaryCtc[randomIndex];
    setExpectedSalaryCtc(randomExpectedSalaryCtc);

    const randomMuchExpectedSalaryCtc = muchExpectedsalaryCtc[randomIndex];
    setMuchExpectedSalaryCtc(randomMuchExpectedSalaryCtc);

    const randomStartedPeriod = startedperiod[randomIndex];
    setStartedPeriod(randomStartedPeriod);

    const randomLongPeriod = longperiod[randomIndex];
    setLongPeriod(randomLongPeriod);

    const randomNegotiablePeriod = negotiableperiod[randomIndex];
    setNegotiablePeriod(randomNegotiablePeriod);

    const randomFullTimeJobExperience = fullTimeJobExperience[randomIndex];
    setFullTimeJobExperience(randomFullTimeJobExperience);

    const randomReportPeople = reportPeople[randomIndex];
    setReportPeople(randomReportPeople);

    const randomTopSkill = skills[randomIndex];
    setTopSkill(randomTopSkill);

    const randomHearAboutUs = hearAboutUs[randomIndex];
    setHearAboutUs(randomHearAboutUs);

    const randomReadyToInterview = readyToInterview[randomIndex];
    setReadyToInterview(randomReadyToInterview);

    const randomJobSearch = jobSearch[randomIndex];
    setJobSearch(randomJobSearch);

    const randomGitHub = github[randomIndex];
    setGitHub(randomGitHub);

    const randomHourlyRateFrom = HourlyRateFrom[randomIndex];
    setHourlyRateFrom(randomHourlyRateFrom);
    
    const randomHourlyRateTo = HourlyRateTo[randomIndex];
    setHourlyRateTo(randomHourlyRateTo);
  };

  useEffect(() => {
    pickRandomName();
  }, []);

  const fnameInputChange = (e) => {
    setRandomName(e.target.value);
  };
  const lastInputChange = (e) => {
    setRandomLastName(e.target.value);
  };
  const userInputChange = (e) => {
    setRandomUserName(e.target.value);
  };
  const userEmailChange = (e) => {
    setRandomEmail(e.target.value);
  };
  const userCountryChange = (e) => {
    setRandomCountryNames(e.target.value);
  };
  const userPasswordsChange = (e) => {
    setPasswords(e.target.value);
  };
  const userInterestedRoleChange = (e) => {
    setInterestedRole(e.target.value);
  };
  // const userInterestedLocationChange = (e) => {
  //   setInterestedLocation(e.target.value);
  // };
  const userTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const userCurrentSalaryCtcChange = (e) => {
    setCurrentSalaryCtc(e.target.value);
  };
  const userFixedSalaryCtcChange = (e) => {
    setFixedSalaryCtc(e.target.value);
  };
  const userExpectedSalaryCtcChange = (e) => {
    setExpectedSalaryCtc(e.target.value);
  };
  const userMuchExpectedSalaryCtcChange = (e) => {
    setMuchExpectedSalaryCtc(e.target.value);
  };
  const userStartedPeriodChange = (e) => {
    setStartedPeriod(e.target.value);
  };
  const userLongPeriodChange = (e) => {
    setLongPeriod(e.target.value);
  };
  const userNegotiablePeriodChange = (e) => {
    setNegotiablePeriod(e.target.value);
  };

  const userFullTimeJobExperienceChange = (e) => {
    setFullTimeJobExperience(e.target.value);
  };
  const userReportPeopleChange = (e) => {
    setReportPeople(e.target.value);
  };
  const userTopSkillChange = (e) => {
    setTopSkill(e.target.value);
  };

  const userHearAboutUsChange = (e) => {
    setHearAboutUs(e.target.value);
  };

  // const userReadyToInterviewChange = (e) => {
  //   setReadyToInterview(e.target.value);
  // };

  const userJobSearchChange = (e) => {
    setJobSearch(e.target.value);
  };

  const userGitHubChange = (e) => {
    setGitHub(e.target.value);
  };

  // checkbox
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // dynamic data ke lie
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const [loading, setLoading] = useState(false); // State variable to track loading state
  const [color, setColor] = useState("#03a9f4");

  const [userSelectedFile, setUserSelectedFile] = useState("");
  
  
  const formSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when form is submitted

    try {
      const user_data1 = {
        accountType: "freelancer",
        confirmPassword: randomPasswords,
        country: data.country || randomCountryNames,
        email: data.email || randomEmail,
        firstName: data.fname || randomName,
        lastName: data.lname || randomLastName,
        password: randomPasswords,
        rules: "on",
        userName: data.uname || randomUserName,
      };
      const res = await axiousPublic.post(`/auth/register`, user_data1);
      // console.log(res);
      // console.log("userprofileIDD", res.data.data.user.userId);
      // alert("SignUp Successfully");

      const user_data2 = {
        email: data.email || randomEmail,
      };
      // console.log("user_data2logg", user_data2);

      // Second API call with data from the first API response
      const verificationResponse = await axiousPublic.patch(`/auth/verifyEmail/oneClick`, user_data2);
      const isValidEmail = verificationResponse.data.isValid;
      setVerificationResult(
        isValidEmail ? "Valid email address!" : "Invalid email address!"
      );

      // Checkbox true
      if (isChecked === true) {

        const profilefreelancerId = res.data.data.user.userId; //data.freelancerId || "234"; // or get it from somewhere
        const config11 = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + res.data.data.token,
          },
        };

        const profile_freelancer_step1 = {
          // interestedRoles: data.interestedRoles || randomInterestedRole,
          interestedRoles: ["front end developer", "full stack developer", "game developer", "ios developer"],
          completedStep: 1,
        };
        const respstep1 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, profile_freelancer_step1, config11);
        console.log(respstep1.data);

        const date_OfBirth_data = {
          dateOfBirth: "Apr 19, 2024",
          languages: ["English"],
        };
        const dateOfBirthdata = await axiousPublic.patch(`/users/${profilefreelancerId}`, date_OfBirth_data, config11);
        console.log(dateOfBirthdata.data);

        const profile_freelancer_step2 = {
          completedStep: 2,
          currentCtc: randomCurrentSalaryCtc, //11,
          expectedCtc: randomExpectedSalaryCtc, // 13,
          expectedFixedCtc: randomFixedSalaryCtc, //7,
          fixedCtc: randomMuchExpectedSalaryCtc, //9,
          hourlyRateFrom: randomHourlyRateFrom ,//100,
          hourlyRateTo: randomHourlyRateTo, //120,
          negotiablePeriod: randomNegotiablePeriod, //"NO, not resigned yet",
          startPeriod: randomStartedPeriod, //"NO, yes
          title: randomTitle, //"dummy testing",
          // longPeriod: randomLongPeriod, //"2 months",
        };
        const respstep2 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, profile_freelancer_step2, config11);
        console.log(respstep2.data);

        const profile_freelancer_step3 = {
          completedStep: 3,
          // isFresher: false,
          isFresher: true,
          jobHistory: [
            {
              // currentCompanyName: "Hire",
              // currentDesignation: "Developer",
              // currentlyEmployed: "yes",
              // employmentType: "Full Time",
              // endDate: "Apr 08, 2024",
              // salary: "50000",
              // skillsUsed: ["html"],
              // startDate: "Apr 08, 2023",
              // summary: "Hello Developer",
              // workingSummaryDetails: "developer",
              currentCompanyName: "",
              currentDesignation: "",
              currentlyEmployed: "",
              employmentType: "",
              endDate: "",
              salary: 0,
              skillsUsed: [],
              startDate: "",
              summary: "",
              // workingSummaryDetails: "developer",
            },
          ],
        };
        const respstep3 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, profile_freelancer_step3, config11 );
        console.log(respstep3.data);

        const profile_freelancer_step4 = {
          completedStep: 4,
          education: [
            {
              addEducation: "10th",
              boardName: "Haryana",
              englishMarks: "98",
              mathMarks: "80",
              schoolMarks: "80- 84.9%",
              schoolMedium: "Hindi",
              schoolPassingYear: "Jul 10, 2024",
            },
          ],
        };
        const respstep4 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, profile_freelancer_step4, config11);
        console.log(respstep4.data);

        const profile_freelancer_step5 = {
          completedStep: 5,
          fullTimeJobExperience: randomFullTimeJobExperience, //"1 years",
          github: randomGitHub, //"https://www.linkedin.com/",
          linkedIn: randomGitHub, //"https://www.linkedin.com/",
          portfolio: randomGitHub, //"https://www.linkedin.com/",
          reportPeople: randomReportPeople, //"Not managing a team",
          // skills: randomTopSkill, //["htmlcss"],
          skills: ["HTML", "CSS"],
          stackOverflow: randomGitHub, //"https://www.linkedin.com/",
        };
        const respstep5 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, profile_freelancer_step5, config11);
        console.log(respstep5.data);

        const profile_freelancer_step6 = {
          hearAboutUs: randomHearAboutUs, //"Job Portal",
          jobSearch: randomJobSearch, //"Open to new opportunities",
          readyToInterview: randomReadyToInterview, //"Yes",
          whyOurCompany: "I want to grow my skills", //randomNegotiablePeriod, //"testing",
          biggestAchievement: "Giving a great presentation at work", //"dummyyy",
          completedStep: 6,
          coverLetter: {},
          resume: {},
        };
        const respstep6 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, profile_freelancer_step6, config11);
        console.log(respstep6.data);

        // start image profile
        const profile_complete1 = {
          isProfileCompleted: true,
          profilePicture: {},
        };
        const respsteppc = await axiousPublic.patch(`/users/${profilefreelancerId}`, profile_complete1, config11);
        console.log(respsteppc.data); 

        const formData = new FormData();
        formData.append("profilePicture", userSelectedFile);
        formData.append("completedStep", 7);
        formData.append("isProfileCompleted", true);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + res.data.data.token,
          },
        };
        const respstep7 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, formData, config);
        console.log(respstep7.data);
        // end image profile

      }

      // setLoading(false); // Set loading state to false when response is received
      alert("Freelancer Created Successfully !!!");
      // toast.success("Data inserted successfully"); // Show success toast
      window.location.reload();
    } catch (error) {
      console.error("Error during form submission:", error);
      // toast.error("Error occurred while submitting the form"); // Show error toast
      toast.error("Data not Submitted Try Again !!!"); // Show error toast
    }
     finally {
      setLoading(false); // Set loading state to false when response is received
    }
  };

  return (
    <div>
      <Header />
      <div className="container">
      <Toaster position="top-center" reverseOrder={false} />
        {loading ? (
          <div className="loader-wrapper">
            <Loader
              type="spinner-circle"
              bgColor={color}
              color={color}
              title={"Processing Your Request"}
              size={100}
            />
          </div>
        ) : (
          <form onSubmit={formSubmit}>
            <div className="row jumbotron box8">
              <div className="col-sm-12 mx-t3 mb-4">
                <h2 className="text-center text-info">Freelancer Register</h2>
              </div>
              <div className="col-sm-6 form-group label1">
                <label htmlFor="name-f">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="fname"
                  id="namef"
                  placeholder="Enter your first name."
                  required=""
                  // value={randomName} onChange={fnameInputChange}
                  value={data.fname || randomName}
                  // onChange={combinedOnChange}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    fnameInputChange(event);
                  }}
                />
              </div>

              <div className="col-sm-6 form-group label1">
                <label htmlFor="name-l">Last name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lname"
                  id="namel"
                  placeholder="Enter your last name."
                  required=""
                  //value={randomLastName} onChange={lastInputChange}
                  value={data.lname || randomLastName}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    lastInputChange(event);
                  }}
                />
              </div>

              <div className="col-sm-6 form-group label1">
                <label htmlFor="pass">Password</label>
                <input
                  type="Password"
                  name="password"
                  className="form-control"
                  id="pass"
                  placeholder="Enter your password."
                  required=""
                  // value='strange'
                  // randomPasswords
                  value={data.password || randomPasswords}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userPasswordsChange(event);
                  }}
                />
              </div>

              <div className="col-sm-6 form-group label1">
                <label htmlFor="pass">Confirm Password</label>
                <input
                  type="text"
                  name="confirmPassword"
                  className="form-control"
                  id="confirmPassword"
                  placeholder="Enter your password."
                  required=""
                  value={randomPasswords}
                  // value={data.password || randomPasswords}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    // userPasswordsChange(event);
                  }}
                />
              </div>

              <div className="col-sm-6 form-group label1">
                <label htmlFor="name-un">User Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="uname"
                  id="name-un"
                  placeholder="Enter your user name."
                  required=""
                  // value={randomUserName} onChange={userInputChange}
                  value={data.uname || randomUserName}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userInputChange(event);
                  }}
                />
              </div>

              <div className="col-sm-6 form-group label1">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  placeholder="Enter your email."
                  required=""
                  // value={randomEmail} onChange={userEmailChange}
                  value={data.email || randomEmail}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userEmailChange(event);
                  }}
                />
              </div>

              <div className="col-sm-6 form-group label1">
                <label htmlFor="Country">Country</label>
                <input
                  type="test"
                  className="form-control"
                  name="country"
                  id="country"
                  placeholder="Enter your Country name."
                  required=""
                  // value={randomCountryNames} onChange={userCountryChange}
                  value={data.country || randomCountryNames}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userCountryChange(event);
                  }}
                />
              </div>

              {/* <div className="col-sm-12 label1">
        <input
          type="checkbox"
          className="form-check d-inline"
          id="chb"
          required=""
        />
        <label htmlFor="chb" className="form-check-label">
          &nbsp;I accept all terms and conditions.
        </label>
      </div>&nbsp; */}

              &nbsp;
              <div className="col-sm-12 label1">
                <input
                  type="checkbox"
                  className="form-check d-inline"
                  id="chb1"
                  required=""
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="chb1" className="form-check-label">
                  &nbsp;Profile fill up
                </label>
              </div>

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="interestedRoles">Interested Role</label>
                  <input
                    type="text"
                    className="form-control"
                    name="interestedRoles"
                    id="interestedRoles"
                    placeholder="Enter your Role"
                    required=""
                    value={data.interestedRoles || randomInterestedRole}
                    onChange={(event) => {
                      // inputEvent(event);
                      userInterestedRoleChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="title">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    id="title"
                    placeholder="Enter your Title"
                    required=""
                    // value={data.profile_title || randomTitle}
                    value={randomTitle}
                    onChange={(event) => {
                      userTitleChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="dateOfBirth">Date of Birthday</label>
                  <input
                    type="text"
                    className="form-control"
                    name="dateOfBirth"
                    id="dateOfBirth"
                    placeholder="Enter your Birth"
                    required=""
                    onChange={(event) => {
                      // userTitleChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="currentCtc">What is your current CTC?</label>
                  <input
                    type="text"
                    className="form-control"
                    name="currentCtc"
                    id="currentCtc"
                    placeholder=""
                    required=""
                    value={randomCurrentSalaryCtc}
                    // value={data.currentCtc || randomCurrentSalaryCtc}
                    onChange={(event) => {
                      userCurrentSalaryCtcChange(event);
                    }}
                  />
                </div>
              )}
              
              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="fixedCtc">
                    How much of your CTC is fixed?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="fixedCtc"
                    id="fixedCtc"
                    placeholder=""
                    required=""
                    value={randomFixedSalaryCtc}
                    // value={data.fixedCtc || randomFixedSalaryCtc}
                    onChange={(event) => {
                      userFixedSalaryCtcChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="expectedCtc">
                    What is your Minimum Expected Total CTC?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="expectedCtc"
                    id="expectedCtc"
                    placeholder=""
                    required=""
                    value={randomExpectedSalaryCtc}
                    // value={data.expectedCtc || randomExpectedSalaryCtc}
                    onChange={(event) => {
                      userExpectedSalaryCtcChange(event);
                    }}
                  />
                  
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="muchExpectedCtc">
                    Of The total expected CTC , how much do you expect as a
                    fixed?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="muchExpectedCtc"
                    id="muchExpectedCtc"
                    placeholder=""
                    required=""
                    value={randomMuchExpectedSalaryCtc}
                    // value={data.muchExpectedCtc || randomMuchExpectedSalaryCtc}
                    onChange={(event) => {
                      userMuchExpectedSalaryCtcChange(event);
                    }}
                  />
                  
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="hourlyRateFrom">
                  Hourly Rate From:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="hourlyRateFrom"
                    id="hourlyRateFrom"
                    placeholder=""
                    required=""
                    value={randomHourlyRateFrom}
                    //  onChange={(event) => {
                    //   userHourlyRateFromChange(event);
                    // }}
                  />
                  
                </div>
              )}
              
              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="hourlyRateTo">
                  Hourly Rate To:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="hourlyRateTo"
                    id="hourlyRateTo"
                    placeholder=""
                    required=""
                    value={randomHourlyRateTo}
                    // onChange={(event) => {
                    //   userHourlyRateToChange(event);
                    // }}
                  />
                  
                </div>
              )}


              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="startPeriod">
                    Have you started serving your notice period?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="startPeriod"
                    id="startPeriod"
                    placeholder=""
                    required=""
                    // value={randomStartedPeriod}
                    value={data.startPeriod || randomStartedPeriod}
                    onChange={(event) => {
                      userStartedPeriodChange(event);
                    }}
                  />
                  
                </div>
              )}
              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="longPeriod">
                    How long is your notice period?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="longPeriod"
                    id="longPeriod"
                    placeholder=""
                    required=""
                    value={randomLongPeriod}
                    // value={data.longPeriod || randomLongPeriod}
                    onChange={(event) => {
                      userLongPeriodChange(event);
                    }}
                  />
                  
                </div>
              )}
              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="negotiablePeriod">
                    Is your notice period negotiable
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="negotiablePeriod"
                    id="negotiablePeriod"
                    placeholder=""
                    required=""
                    value={randomNegotiablePeriod}
                    // value={data.negotiablePeriod || randomNegotiablePeriod}
                    onChange={(event) => {
                      userNegotiablePeriodChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="isFresher">
                  Are You Fresher?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="isFresher"
                    id="isFresher"
                    placeholder=""
                    required=""
                    value="No"
                    // value={randomIsFresher}
                    // onChange={(event) => {
                    //   userIsFresherChange(event);
                    // }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="workingSummaryDetails">
                  Career Work Summary
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="workingSummaryDetails"
                    id="workingSummaryDetails"
                    placeholder=""
                    required=""
                    value="developer"
                    // value={randomWorkingSummaryDetails}
                    // onChange={(event) => {
                    //   userWorkingSummaryDetailsChange(event);
                    // }}
                  />
                </div>
              )}

    
              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="currentlyEmployed">
                    Is This your Employment
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="currentlyEmployed"
                    id="currentlyEmployed"
                    placeholder=""
                    required=""
                    value="yes"
                    // value={data.negotiablePeriod || randomNegotiablePeriod}
                    onChange={(event) => {
                      // userNegotiablePeriodChange(event);
                    }}
                  />
                </div>
              )}
              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="employmentType">Employment Type</label>
                  <input
                    type="text"
                    className="form-control"
                    name="employmentType"
                    id="employmentType"
                    placeholder=""
                    required=""
                    value="Full Time"
                    // value={data.negotiablePeriod || randomNegotiablePeriod}
                    onChange={(event) => {
                      // userNegotiablePeriodChange(event);
                    }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="currentCompanyName">
                  Current Company Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="currentCompanyName"
                    id="currentCompanyName"
                    placeholder=""
                    required=""
                    value="Hire"
                    // value={randomCurrentCompanyName}
                    // onChange={(event) => {
                    //   userCurrentCompanyNameChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="currentDesignation">
                  Current Designation
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="currentDesignation"
                    id="currentDesignation"
                    placeholder=""
                    required=""
                    value="Developer"
                    // value={randomCurrentDesignation}
                    // onChange={(event) => {
                    //   userCurrentDesignationChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="startDate">
                  Joining Date
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="startDate"
                    id="startDate"
                    placeholder=""
                    required=""
                    value="Apr 08, 2023"
                    // value={randomStartDate}
                    // onChange={(event) => {
                    //   userStartDateChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="endDate">
                  Ending Date
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="endDate"
                    id="endDate"
                    placeholder=""
                    required=""
                    value="Apr 08, 2024"
                    // value={randomEndDate}
                    // onChange={(event) => {
                    //   userEndDateChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="salary">
                  Current Salary
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="salary"
                    id="salary"
                    placeholder=""
                    required=""
                    value="50000"
                    // value={randomSalary}
                    // onChange={(event) => {
                    //   userSalaryChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="skillsUsed">
                 Skill Used
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="skillsUsed"
                    id="skillsUsed"
                    placeholder=""
                    required=""
                    value={["html"]}
                    // value={randomSkillsUsed}
                    // onChange={(event) => {
                    //   userSkillsUsedChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="summary">
                 Summary
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="summary"
                    id="summary"
                    placeholder=""
                    required=""
                    value="Hello Developer"
                    // value={randomSummary}
                    // onChange={(event) => {
                    //   userSummaryChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="addEducation">
                  Education
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="addEducation"
                    id="addEducation"
                    placeholder=""
                    required=""
                    value="10th"
                    // value={randomAddEducation}
                    // onChange={(event) => {
                    //   userAddEducationChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="boardName">
                  Board
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="boardName"
                    id="boardName"
                    placeholder=""
                    required=""
                    value="Haryana"
                    // value={randomBoardName}
                    // onChange={(event) => {
                    //   userBoardNameChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="schoolPassingYear">
                  Passing out Year
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="schoolPassingYear"
                    id="schoolPassingYear"
                    placeholder=""
                    required=""
                    value="Jul 10, 2024"
                    // value={randomSchoolPassingYear}
                    // onChange={(event) => {
                    //   userSchoolPassingYearChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="schoolMedium">
                   School Medium
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="schoolMedium"
                    id="schoolMedium"
                    placeholder=""
                    required=""
                    value="Hindi"
                    // value={randomSchoolMedium}
                    // onChange={(event) => {
                    //   userSchoolMediumChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="schoolMarks">
                  Total Marks
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="schoolMarks"
                    id="schoolMarks"
                    placeholder=""
                    required=""
                    value="80- 84.9%"
                    // value={randomSchoolMarks}
                    // onChange={(event) => {
                    //   userSchoolMarksChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="englishMarks">
                 English Marks
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="englishMarks"
                    id="englishMarks"
                    placeholder=""
                    required=""
                    value="98"
                    // value={randomEnglishMarks}
                    // onChange={(event) => {
                    //   userEnglishMarksChange(event);
                    // }}
                  />
                </div>
              )}

          {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="mathMarks">
                  Math Marks
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="mathMarks"
                    id="mathMarks"
                    placeholder=""
                    required=""
                    value="80"
                    // value={randomMathMarks}
                    // onChange={(event) => {
                    //   userMathMarksChange(event);
                    // }}
                  />
                </div>
              )}

              {/* experience */}
              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="fullTimeJobExperience">
                    How many years do you have a full time experience?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="fullTimeJobExperience"
                    id="fullTimeJobExperience"
                    placeholder=""
                    required=""
                    // value={randomFullTimeJobExperience} onChange={userFullTimeJobExperienceChange}
                    value={
                      data.fullTimeJobExperience ||
                      randomFullTimeJobExperience
                    }
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userFullTimeJobExperienceChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="reportPeople">
                    How many people report to you ? (Directly or Indirectly)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="reportPeople"
                    id="reportPeople"
                    placeholder=""
                    required=""
                    // value={randomReportPeople} onChange={userReportPeopleChange}
                    value={data.reportPeople || randomReportPeople}
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userReportPeopleChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="skills">
                    {/* Pick your top frameworks, skills and technologies.(up to 8) */}
                    Selected Experience Skills
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="skills"
                    id="skills"
                    placeholder=""
                    required=""
                    // value={randomTopSkill} onChange={userTopSkillChange}
                    value={["HTML", "CSS"]}
                    // value={data.skills || randomTopSkill}
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userTopSkillChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="github">GitHub</label>
                  <input
                    type="text"
                    className="form-control"
                    name="github"
                    id="github"
                    placeholder=""
                    required="true"
                    value={data.github || randomGitHub}
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="linkedIn">Linkedin</label>
                  <input
                    type="text"
                    className="form-control"
                    name="linkedIn"
                    id="linkedIn"
                    placeholder=""
                    required="true"
                    value={data.linkedIn || randomGitHub}
                    // value={data.linkedin || randomGitHub}
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="portfolio">Portfolio</label>
                  <input
                    type="text"
                    className="form-control"
                    name="portfolio"
                    id="portfolio"
                    placeholder=""
                    required="true"
                    value={data.portfolio || randomGitHub}
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="stackOverflow">StackOverflow</label>
                  <input
                    type="text"
                    className="form-control"
                    name="stackOverflow"
                    id="stackOverflow"
                    placeholder=""
                    required="true" stackOverflow
                    value={data.stackOverflow || randomGitHub}
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                  />
                </div>
              )}

              {/* complete */}
              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="hearAboutUs">
                    How did you hear about us ?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="hearAboutUs"
                    id="hearAboutUs"
                    placeholder=""
                    required=""
                    // value={randomHearAboutUs} onChange={userHearAboutUsChange}
                    value={data.hearAboutUs || randomHearAboutUs}
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userHearAboutUsChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="jobSearch">
                    Where are you in your job search ?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="jobSearch"
                    id="jobSearch"
                    placeholder=""
                    required=""
                    // value={randomJobSearch} onChange={userJobSearchChange}
                    value={data.jobSearch || randomJobSearch}
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userJobSearchChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="readyToInterview">
                    When will you be ready to start interviewing with company?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="readyToInterview"
                    id="readyToInterview"
                    placeholder=""
                    required=""
                    value={data.readyToInterview || randomReadyToInterview}
                    // onChange={inputEvent}
                    // onChange={(event) => {
                    //   inputEvent(event);
                    //   userStartedPeriodChange(event);
                    // }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="whyOurCompany">
                    Why do you want to work in our company?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="whyOurCompany"
                    id="whyOurCompany"
                    placeholder=""
                    required=""
                    value={data.whyOurCompany || "I want to grow my skills"}
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userNegotiablePeriodChange(event);
                    }}
                  />
                </div>
              )}

              {/* extras */}
              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="biggestAchievement">
                    What is your biggest Achievement
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="biggestAchievement"
                    id="biggestAchievement"
                    placeholder=""
                    required=""
                    value={data.biggestAchievement || "Giving a great presentation at work"}
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userLongPeriodChange(event);
                    }}
                  />
                </div>
              )}

              {isChecked && (
                <div className="col-sm-6 form-group label1">
                  <label htmlFor="profilePicture">
                   Select Image
                  </label>
                  <input
                  type="file"
                  id="profilePicture"
                  name="profilePicture"
                  accept="image/*"
                  onChange={(e) => setUserSelectedFile(e.target.files[0])}
                  className="form-control"
                  required="true"                    
                  />
                </div>
              )}
              
              <div className="col-sm-12 form-group mb-0">
                <button type="submit" className="btn btn-primary float-right">
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Freelance;
