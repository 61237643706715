import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Loader from "react-js-loader";
import toast, { Toaster } from "react-hot-toast";
import useAxiosPublic from "../../Hooks/useAxiosPublic";

const ProfilefillClientCompany = () => {
  const axiousPublic = useAxiosPublic();

  // const [email_id, emailid] = useState("");
  const [randomName, setRandomName] = useState("");
  const [randomLastName, setRandomLastName] = useState("");
  // const [randomEmail, setRandomEmail] = useState("");
  const [randomUserName, setRandomUserName] = useState("");
  //   const [randomCountryNames, setRandomCountryNames] = useState("");
  //   const [randomPasswords, setPasswords] = useState("");
  const [randomAddress, setAddress] = useState("");
  const [randomContact, setContact] = useState("");
  const [randomCompanyDetails, setCompanyDetails] = useState("");
  const [randomNoOfEmployees, setNoOfEmployees] = useState("");
  const [randomRegisterType, setRegisterType] = useState("");
  const [randomDescription, setDescription] = useState("");
  const [randomCompanyName, setCompanyName] = useState("");
  const [randomTagline, setTagline] = useState("");
  const [randomGSTINs, setGSTINs] = useState("");
  const [randomGitHub, setGitHub] = useState("");
  const [randomRegistrationNumberCIN, setRegistrationNumberCIN] = useState("");
  const [randomCompanyURL, setCompanyURL] = useState("");
  const [randomCompanyEmail, setCompanyEmail] = useState("");

  const names = ["John", "Ishaan", "Divya", "Kavi", "Aditi"];
  const lastNames = ["Doe", "Boe", "Dane", "Kumar", "Sharma"];
  const userNames = ["Koyal", "Varun", "Bobc", "Aliced", "Same"];
  const address = ["Laxmi Nagar", "Preet Vihar", "Govindpuram", "Vijay Nagar", "Raj Nagar"];
  const contact = ["1237698764", "4565674678", "7893498767", "3453657876", "2348765876"];
  const companydetails = [
    "Accounting and Finance",
    "Customer Service or Operations",
    "Engineering Or Product Management",
    "Human Resource Management",
    "PHP Developer",
    "Production",
    "Purchasing",
    "Research and Development",
    "Sales",
  ];
  const noofemployees = [
    "Its Just Me",
    "10-99 Employees",
    "100-1000 Employees",
    "2-9 Employees",
    "More Than 1000 Employees",
  ];
  const companyname = ["TCS", "HCL", "Setu", "Tech-Mahindra", "Motherson"];

  const registertypes = [
    "On Behalf of Company/Business",
    "On Behalf of Company/Business",
    "On Behalf of Company/Business",
    "On Behalf of Company/Business",
    "On Behalf of Company/Business",
  ];

  const taglines = ["NodeJs", "Java", "PHP", "Python", "Django"];
  const gstins = ["69876", "74678", "74987", "34536", "23487"];
  const registrationNumberCINs = ["65476", "98765", "34526", "98456", "34876"];
  const descriptions = ["Testing", "Dummy", "Testing", "Dummy", "Testing"];
  const github = [
    "https://www.linkedin.com",
    "https://www.linkedin.com",
    "https://www.linkedin.com",
    "https://www.linkedin.com",
    "https://www.linkedin.com",
  ];
  const companyURLs = [
    "http://www.hire.elite-professionals.in",
    "http://www.hire.elite-professionals.in",
    "http://www.hire.elite-professionals.in",
    "http://www.hire.elite-professionals.in",
    "http://www.hire.elite-professionals.in",
  ];
  const companyemails = [
    "abc@gmail.com",
    "def@gmail.com",
    "ghi@gmail.com",
    "jkl@gmail.com",
    "mno@gmail.com",
  ];

  const pickRandomName = () => {
    const randomIndex = Math.floor(
      Math.random() *
        //   names.length,
        lastNames.length,
      userNames.length,

      address.length,
      contact.length,
      companydetails.length,
      noofemployees.length,
      companyname.length,
      taglines.length,
      gstins.length,
      registertypes.length,
      descriptions.length,
      github.length,
      registrationNumberCINs.lenght,
      companyURLs.length,
      companyemails.length
    );

    const randomName = names[randomIndex];
    setRandomName(randomName);

    const randomLastName = lastNames[randomIndex];
    setRandomLastName(randomLastName);

    const randomUserName = userNames[randomIndex];
    setRandomUserName(randomUserName);

    const randomAddress = address[randomIndex];
    setAddress(randomAddress);

    const randomContact = contact[randomIndex];
    setContact(randomContact);

    const randomCompanyDetails = companydetails[randomIndex];
    setCompanyDetails(randomCompanyDetails);

    const randomNoOfEmployees = noofemployees[randomIndex];
    setNoOfEmployees(randomNoOfEmployees);

    const randomRegisterType = registertypes[randomIndex];
    setRegisterType(randomRegisterType);

    const randomCompanyName = companyname[randomIndex];
    setCompanyName(randomCompanyName);

    const randomTagline = taglines[randomIndex];
    setTagline(randomTagline);

    const randomGSTINs = gstins[randomIndex];
    setGSTINs(randomGSTINs);

    const randomDescription = descriptions[randomIndex];
    setDescription(randomDescription);

    const randomRegistrationNumberCIN = registrationNumberCINs[randomIndex];
    setRegistrationNumberCIN(randomRegistrationNumberCIN);

    const randomCompanyURL = companyURLs[randomIndex];
    setCompanyURL(randomCompanyURL);

    const randomCompanyEmail = companyemails[randomIndex];
    setCompanyEmail(randomCompanyEmail);

    const randomGitHub = github[randomIndex];
    setGitHub(randomGitHub);

  };

  useEffect(() => {
    pickRandomName();
  }, []);

  // const fnameInputChange = (e) => {
  //   setRandomName(e.target.value);
  // };
  // const lastInputChange = (e) => {
  //   setRandomLastName(e.target.value);
  // };
  // const userInputChange = (e) => {
  //   setRandomUserName(e.target.value);
  // };

  const userAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const userContactChange = (e) => {
    setContact(e.target.value);
  };
  const userCompanyDetailsChange = (e) => {
    setCompanyDetails(e.target.value);
  };

  const userNoOfEmployeesChange = (e) => {
    setNoOfEmployees(e.target.value);
  };

  // const userRegisterTypeChange = (e) => {
  //   setRegisterType(e.target.value);
  // };

  const userCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  const userTaglineChange = (e) => {
    setTagline(e.target.value);
  };

  const userGSTINsChange = (e) => {
    setGSTINs(e.target.value);
  };

  const userDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  // const userRegistrationNumberCINChange = (e) => {
  //   setRegistrationNumberCIN(e.target.value);
  // };

  // const userCompanyURLChange = (e) => {
  //   setCompanyURL(e.target.value);
  // };

  const userCompanyEmailChange = (e) => {
    setCompanyEmail(e.target.value);
  };

  const userGitHubChange = (e) => {
    setGitHub(e.target.value);
  };

  // dynamic data ke lie
  const [data, setData] = useState({
    dateOfBirth: "",
    phone: "",
    description: "",
    companyDetailsDepartment: "",
    numberOfEmployees: "",
    companyName: "",
    GSTIN: "",
    tagLine: "",
    registeredOffice: "",
    companyContact: "",
    companyEmail: "",

    facebook: "",
    github: "",
    instagram: "",
    linkedIn: "",
    othersLink: "",
    stackOverflow: "",
    youtube: "",
    
    // GSTIN: "",
    // Identity: "",
    // companyContact: "",
    // companyCountry: "",
    // companyDetailsDepartment: "",
    // companyEmail: "",
    // companyLogo: "",
    // companyName: "",
    // description: "",
    // displayName: "",
    // firstName: "",
    // lastName: "",
    // numberOfEmployeesValue: "",
    // numberOfEmployees: "",
    // profilePic: "",
    // registeredOffice: "",
    // tagLine: "",
    // userEmail: "",
    // facebook: "",
    // github: "",
    // instagram: "",
    // linkedIn: "",
    // othersLink: "",
    // stackOverflow: "",
    // youtube: "",
    // aadHaarCard: "",
    // bankAcc: "",
    // incomeTax: "",
    // insurance: "",
    // passport: "",
  });

  // dynamic data ke lie
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false); // State variable to track loading state
  const [color, setColor] = useState("#03a9f4");

  const [userSelectedFile, setUserSelectedFile] = useState("");
  // const [userCompanyLogoSelectedFile, setCompanyLogoSelectedFile] = useState("");
  // const [userProfilePictureSelectedFile, setProfilePictureSelectedFile] = useState("");
  const [broucherSelectedFile, setBroucherSelectedFile] = useState("");

  const formSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when form is submitted

    try {
      
      const responselogin = await axiousPublic.post(`/auth/login`, {
        email,
        password,
      });
      console.log("userlogindataID", responselogin.data.data.user.userId);
      const profileClientId = responselogin.data.data.user.userId;

      const config11 = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + responselogin.data.data.token,
        },
      };

      const formData = new FormData();
          formData.append("registerType", randomRegisterType);
          formData.append("dateOfBirth", "Jul 03, 2024");
          formData.append("phone", randomContact);
          formData.append("description", randomDescription);
          formData.append("companyDetailsDepartment", randomCompanyDetails);
          formData.append("numberOfEmployees", randomNoOfEmployees);
          formData.append("completedStep", 2);
          formData.append("companyName", randomCompanyName);
          formData.append("GSTIN", randomGSTINs);
          formData.append("tagLine", randomTagline);
          formData.append("registeredOffice", randomAddress);
          formData.append("companyContact", randomContact);
          formData.append("companyEmail", randomCompanyEmail);
          formData.append("Identity", userSelectedFile);
          formData.append("companyLogo", userSelectedFile);
          formData.append("profilePicture", userSelectedFile);
      const respstep1 = await axiousPublic.patch(`/clientProfile/${profileClientId}`, formData, config11);
      console.log(respstep1.data);

      const profile_client_step2 = {
        completedStep: 3,
        facebook: data.facebook || randomGitHub,
        github: data.github || randomGitHub,
        instagram: data.instagram || randomGitHub,
        linkedIn: data.linkedIn || randomGitHub,
        othersLink: data.othersLink || randomGitHub,
        stackOverflow: data.stackOverflow || randomGitHub,
        youtube: data.youtube || randomGitHub,
      };
      const respstep2 = await axiousPublic.patch(`/clientProfile//${profileClientId}`, profile_client_step2, config11);
      console.log(respstep2.data);

      const profile_client_step3 = new FormData();
        profile_client_step3.append("brochures", broucherSelectedFile);
        profile_client_step3.append("completedStep", 4);
        const respstep3 = await axiousPublic.post(`/clientProfile//brochures/${profileClientId}`, profile_client_step3, config11);
        console.log(respstep3.data);

        // alert("Profile data inserted");
      alert("Client Profile Fill Up Created Successfully !!!");

      window.location.reload();
    } catch (error) {
      console.error(error.message);
      toast.error("Data not Submitted Try Again !!!"); // Show error toast
    } finally {
      setLoading(false); // Set loading state to false when response is received
    }
  };

  return (
    <>
      <Header />

      <div className="container">
        <Toaster position="top-center" reverseOrder={false} />
        {loading ? (
          <div className="loader-wrapper">
            <Loader
              type="spinner-circle"
              bgColor={color}
              color={color}
              title={"Processing Your Request"}
              size={100}
            />
          </div>
        ) : (
          <form onSubmit={formSubmit}>
            {/* <form> */}
            <div className="column jumbotron box8">
              <h4 className=" text-info mb-5 texth4">
                Profile Fill Up by Existing Client With Company
              </h4>

              <div className="form-group row">
                <label htmlFor="email" className="col-sm-5 col-form-label">
                  Client Email ID
                </label>
                <div className="col-sm-7">
                  <input
                    type="Email"
                    name="email"
                    id="email"
                    className="form-control"
                    // onChange={(event) => emailid(event.target.value)}
                    onChange={(e) => setEmail(e.target.value)}
                    required="true"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="password" className="col-sm-5 col-form-label">
                  Password
                </label>
                <div className="col-sm-7">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    // onChange={(event) => emailid(event.target.value)}
                    onChange={(e) => setPassword(e.target.value)}
                    required="true"
                  />
                </div>
              </div>

              <div className="form-group row">
              <label htmlFor="registerType" className="col-sm-5 col-form-label">
                RegisterType
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  name="registerType"
                  id="registerType"
                  placeholder=""
                  required=""
                  value={randomRegisterType}
                  // value={data.registerType || randomRegisterType}
                  // onChange={(event) => {
                  //   inputEvent(event);
                  //   userRegisterTypeChange(event);
                  // }}
                />
              </div>
            </div>

              <div className="form-group row">
                <label
                  htmlFor="dateOfBirth"
                  className="col-sm-5 col-form-label"
                >
                  Date of birth
                </label>
                <div className="col-sm-7">
                  <input
                    type="date"
                    className="form-control"
                    name="dateOfBirth"
                    id="dateOfBirth"
                    placeholder=""
                    required=""
                    // value={data. || randomUserName} //randomUserName
                    // // onChange={inputEvent}
                    // onChange={(event) => {
                    //   inputEvent(event);
                    //   userInputChange(event);
                    // }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="contact"
                  className="col-sm-5 col-form-label"
                >
                  Phone
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="contact"
                    id="contact"
                    placeholder=""
                    required=""
                    value={data.contact || randomContact} //randomUserName
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userContactChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="description"
                  className="col-sm-5 col-form-label"
                >
                  Description
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    id="description"
                    placeholder=""
                    required=""
                    value={data.description || randomDescription}
                    onChange={(event) => {
                      inputEvent(event);
                      userDescriptionChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="companyDetailsDepartment"
                  className="col-sm-5 col-form-label"
                >
                  Company Details
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="companyDetailsDepartment"
                    id="companyDetailsDepartment"
                    placeholder=""
                    required=""
                    value={data.companyDetailsDepartment || randomCompanyDetails
                    }
                    onChange={(event) => {
                      inputEvent(event);
                      userCompanyDetailsChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="numberOfEmployees"
                  className="col-sm-5 col-form-label"
                >
                  No. Of Employees
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="numberOfEmployees"
                    id="numberOfEmployees"
                    placeholder=""
                    required=""
                    value={data.numberOfEmployees || randomNoOfEmployees}
                    onChange={(event) => {
                      inputEvent(event);
                      userNoOfEmployeesChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="companyName"
                  className="col-sm-5 col-form-label"
                >
                  Company Name
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="companyName"
                    id="companyName"
                    placeholder=""
                    required=""
                    value={data.companyName || randomCompanyName}
                    onChange={(event) => {
                      inputEvent(event);
                      userCompanyNameChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="GSTIN" className="col-sm-5 col-form-label">
                  GSTIN
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="GSTIN"
                    id="GSTIN"
                    placeholder=""
                    required=""
                    value={data.GSTIN || randomGSTINs}
                    onChange={(event) => {
                      inputEvent(event);
                      userGSTINsChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="tagLine" className="col-sm-5 col-form-label">
                  Tag Line
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="tagLine"
                    id="tagLine"
                    placeholder=""
                    required=""
                    value={data.tagLine || randomTagline} //userTaglineChange
                    onChange={(event) => {
                      inputEvent(event);
                      userTaglineChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="registeredOffice"
                  className="col-sm-5 col-form-label"
                >
                  Address of Registered Office
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="registeredOffice"
                    id="registeredOffice"
                    placeholder=""
                    required=""
                    value={data.registeredOffice || randomAddress}
                    onChange={(event) => {
                      inputEvent(event);
                      userAddressChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="companyContact" className="col-sm-5 col-form-label">
                  Company Contact
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="companyContact"
                    id="companyContact"
                    placeholder=""
                    required=""
                    value={data.companyContact || randomContact}
                    onChange={(event) => {
                      inputEvent(event);
                      userContactChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="companyEmail"
                  className="col-sm-5 col-form-label"
                >
                  Company Email
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="companyEmail"
                    id="companyEmail"
                    placeholder=""
                    required=""
                    value={data.companyEmail || randomCompanyEmail}
                    onChange={(event) => {
                      inputEvent(event);
                      userCompanyEmailChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="Identity"
                  className="col-sm-5 col-form-label"
                >
                  Identity
                </label>
                <div className="col-sm-7">
                  <input
                    type="file"
                    id="Identity"
                    name="Identity"
                    accept="image/*"
                    onChange={(e) => setUserSelectedFile(e.target.files[0])}
                    className="form-control"
                    required="true"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="companyLogo"
                  className="col-sm-5 col-form-label"
                >
                  CompanyLogo
                </label>
                <div className="col-sm-7">
                  <input
                    type="file"
                    id="companyLogo"
                    name="companyLogo"
                    accept="image/*"
                    className="form-control"
                    onChange={(e) => setUserSelectedFile(e.target.files[0])}
                    required="true"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="profilePicture"
                  className="col-sm-5 col-form-label"
                >
                  Profile Picture
                </label>
                <div className="col-sm-7">
                  <input
                    type="file"
                    id="profilePicture"
                    name="profilePicture"
                    accept="image/*"
                    onChange={(e) => setUserSelectedFile(e.target.files[0])}
                    className="form-control"
                    required="true"
                  />
                </div>
              </div>

              {/* social link */}
              <div className="form-group row">
                <label htmlFor="facebook" className="col-sm-5 col-form-label">
                  Facebook
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="facebook"
                    id="facebook"
                    placeholder=""
                    required=""
                    value={data.facebook || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="github" className="col-sm-5 col-form-label">
                  GitHub
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="github"
                    id="github"
                    placeholder=""
                    required=""
                    value={data.github || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="instagram" className="col-sm-5 col-form-label">
                  Instagram
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="instagram"
                    id="instagram"
                    placeholder=""
                    required=""
                    value={data.instagram || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="linkedIn" className="col-sm-5 col-form-label">
                  LinkedIn
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="linkedIn"
                    id="linkedIn"
                    placeholder=""
                    required=""
                    value={data.linkedIn || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="othersLink" className="col-sm-5 col-form-label">
                  OthersLink
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="othersLink"
                    id="othersLink"
                    placeholder=""
                    required=""
                    value={data.othersLink || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="stackOverflow"
                  className="col-sm-5 col-form-label"
                >
                  StackOverflow
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="stackOverflow"
                    id="stackOverflow"
                    placeholder=""
                    required=""
                    value={data.stackOverflow || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="youtube" className="col-sm-5 col-form-label">
                  YouTube
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="youtube"
                    id="youtube"
                    placeholder=""
                    required=""
                    value={data.youtube || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="brochures"
                  className="col-sm-5 col-form-label"
                >
                  Brochures
                </label>
                <div className="col-sm-7">
                  <input
                    type="file"
                    id="brochures"
                    name="brochures"
                    accept="image/*"
                    className="form-control"
                    placeholder=""
                    required="true"
                    onChange={(e) => setBroucherSelectedFile(e.target.files[0])}
                  />
                </div>
              </div>

              <br />

              <div className=" form-group mb-0">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default ProfilefillClientCompany;
