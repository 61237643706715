import React, { useEffect, useState } from "react";
import Header from "../Header";
import "./postjobopening.css";
import Loader from "react-js-loader";
import toast, { Toaster } from "react-hot-toast";
import useAxiosPublic from "../Hooks/useAxiosPublic";

const PostJobOpeningMilestoneProject = () => {
  const axiousPublic = useAxiosPublic();

  const [randomProjectLevel, setrandomProjectLevel] = useState("");
  const [randomJobDuration, setrandomJobDuration] = useState("");
  const [randomFreelancerType, setrandomFreelancerType] = useState("");
  const [randomEnglishLevel, setrandomEnglishLevel] = useState("");
  const [
    randomYearsofExperiencePreferred,
    setrandomYearsofExperiencePreferred,
  ] = useState("");
  const [randomJobCategories, setrandomJobCategories] = useState("");
  const [randomProjectLocationType, setrandomProjectLocationType] =
    useState("");
  const [randomJobTitle, setrandomJobTitle] = useState("");
  const [randomWorkPrice, setrandomWorkPrice] = useState("");
  const [randomJobDetails, setrandomJobDetails] = useState("");
  const [randomJobLocation, setrandomJobLocation] = useState("");

  const projectlevels = [
    "Beginner",
    "Intermediate",
    "Advanced",
    "Expert",
    "Intermediate",
  ];
  // const projectlevels = ["basic level", "expensive", "medium level"];
  const jobdurations = [
    "01 to 03 months",
    "03 to 06 months",
    "more than 6 months",
    "03 to 06 months",
    "more than 6 months",
  ];
  const freelancertypes = [
    "Agency freelancer",
    "Independent freelancer",
    "New rising talent",
    "Independent freelancer",
    "New rising talent",
  ];
  const englishlevels = [
    "Basic",
    "Conversational",
    "Professional",
    "Conversational",
    "Professional",
  ];
  const yearsofexperiencepreferreds = ["0-2", "2-4", "4-6", "6-8", "8-10"];
  const jobcategoriess = [
    "Information Technology (IT) Management",
    "Information Security",
    "Technical Support",
    "Information Security",
    "Technical Support",
  ];
  const projectlocationtypes = [
    "onsite",
    "hybrid",
    "remote",
    "hybrid",
    "remote",
  ];
  // const projectlocationtypes = ["onsite", "partial_onsite", "remote"];
  const jobtitles = ["React", "Node", "Java", "Node", "Java"];
  const workprices = ["2000", "4000", "6000", "8000", "9000"];
  const jobdetails = [
    "Intern",
    "Fresher",
    "Experience",
    "Fresher",
    "Experience",
  ];
  const joblocations = ["Ghaziabad", "Noida", "Delhi", "Noida", "Delhi"];

  const pickRandomName = () => {
    const randomIndex = Math.floor(
      Math.random() * projectlevels.length,
      jobdurations.length,
      freelancertypes.length,
      englishlevels.length,
      yearsofexperiencepreferreds.length,
      jobcategoriess.length,
      projectlocationtypes.length,
      jobtitles.length,
      workprices.length,
      jobdetails.length,
      joblocations.length
    );

    const randomProjectLevel = projectlevels[randomIndex];
    setrandomProjectLevel(randomProjectLevel);

    const randomJobDuration = jobdurations[randomIndex];
    setrandomJobDuration(randomJobDuration);

    const randomFreelancerType = freelancertypes[randomIndex];
    setrandomFreelancerType(randomFreelancerType);

    const randomEnglishLevel = englishlevels[randomIndex];
    setrandomEnglishLevel(randomEnglishLevel);

    const randomYearsofExperiencePreferred =
      yearsofexperiencepreferreds[randomIndex];
    setrandomYearsofExperiencePreferred(randomYearsofExperiencePreferred);

    const randomJobCategories = jobcategoriess[randomIndex];
    setrandomJobCategories(randomJobCategories);

    const randomProjectLocationType = projectlocationtypes[randomIndex];
    setrandomProjectLocationType(randomProjectLocationType);

    const randomJobTitle = jobtitles[randomIndex];
    setrandomJobTitle(randomJobTitle);

    const randomWorkPrice = workprices[randomIndex];
    setrandomWorkPrice(randomWorkPrice);

    const randomJobDetails = jobdetails[randomIndex];
    setrandomJobDetails(randomJobDetails);

    const randomJobLocation = joblocations[randomIndex];
    setrandomJobLocation(randomJobLocation);
  };

  useEffect(() => {
    pickRandomName();
  }, []);

  // const userProjectLevelChange = (e) => {
  //   setrandomProjectLevel(e.target.value);
  // };
  const userJobDurationChange = (e) => {
    setrandomJobDuration(e.target.value);
  };
  const userFreelancerTypeChange = (e) => {
    setrandomFreelancerType(e.target.value);
  };
  const userEnglishLevelChange = (e) => {
    setrandomEnglishLevel(e.target.value);
  };
  const userYearsofExperiencePreferredChange = (e) => {
    setrandomYearsofExperiencePreferred(e.target.value);
  };
  const userJobCategoriesChange = (e) => {
    setrandomJobCategories(e.target.value);
  };

  const userProjectLocationTypeChange = (e) => {
    setrandomProjectLocationType(e.target.value);
  };

  const userrJobTitleChange = (e) => {
    setrandomJobTitle(e.target.value);
  };

  const userrWorkPriceChange = (e) => {
    setrandomWorkPrice(e.target.value);
  };

  const userJobDetailsChange = (e) => {
    setrandomJobDetails(e.target.value);
  };

  const userJobLocationChange = (e) => {
    setrandomJobLocation(e.target.value);
  };

  // date generate
  const [randomprojectStartDate, setrandomProjectStartDate] = useState("");
  const [randomprojectExpireDate, setrandomProjectExpireDate] = useState("");
  const [randomprojectDeadlineDate, setrandomProjectDeadlineDate] =
    useState("");

  //  date generate

  useEffect(() => {
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    const fourDaysLater = new Date(currentDate);
    fourDaysLater.setDate(currentDate.getDate() + 4);

    // Format the dates as YYYY-MM-DD
    const formattedCurrentDate = formatDate(currentDate);
    const formattedTwoDaysLater = formatDate(twoDaysLater);
    const formattedFourDaysLater = formatDate(fourDaysLater);

    setrandomProjectStartDate(formattedCurrentDate);
    setrandomProjectExpireDate(formattedTwoDaysLater);
    setrandomProjectDeadlineDate(formattedFourDaysLater);
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const usersetProjectStartDate = (e) => {
    setrandomProjectStartDate(e.target.value);
  };
  const usersetProjectExpireDate = (e) => {
    setrandomProjectExpireDate(e.target.value);
  };
  const usersetProjectDeadlineDate = (e) => {
    setrandomProjectDeadlineDate(e.target.value);
  };

  const formattedStartDate = `${randomprojectStartDate}T00:00:00.000Z`;
  const formattedDeadlineDate = `${randomprojectDeadlineDate}T00:00:00.000Z`;
  const formattedExpireDate = `${randomprojectExpireDate}T00:00:00.000Z`;

  const [job_title, setJobTitle] = useState("");
  // const [project_level, setProjectLevel] = useState("");
  const [job_duration, setJobDuration] = useState("");
  const [freelancer_type, setFreelancerType] = useState("");
  const [english_level, setEnglishLevel] = useState("");
  const [project_location_type, setProjectLocationType] = useState("");
  const [years_of_experience_preferred, setYearsOfExperiencePreferred] =
    useState("");
  const [project_start_date, setProjectStartDate] = useState("");
  const [project_expire_date, setProjectExpireDate] = useState("");
  const [project_deadline_date, setProjectDeadLineDate] = useState("");
  // const [fixed_project, setFixedProject] = useState("");
  const [minimum_price, setWorkPrice] = useState("");
  const [job_categories, setJobCategories] = useState("");
  const [language, setLanguage] = useState("");
  const [requiredSkills, setrequiredSkills] = useState("");
  const [job_details, setJobDetails] = useState("");
  const [JobLocation, setLocation] = useState("");
  const [userSelectedFile, setUserSelectedFile] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [userclientId, setUserId] = useState("125");
  // const [userclientId, setUserId] = useState("");

  const [loading, setLoading] = useState(false); // State variable to track loading state
  const [color, setColor] = useState("#03a9f4");

  const formSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading state to true when form is submitted

    try {
      const responselogin = await axiousPublic.post(`/auth/login`, {
        email,
        password,
      });
      console.log("userlogindataID", responselogin.data.data.user.userId);
      // const jobclientId = responselogin.data.data.user.userId;

      const config11 = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + responselogin.data.data.token,
          Id: responselogin.data.data.user.userId,
        },
      };

      const formData = new FormData();

      formData.append("title", randomJobTitle);
      formData.append("projectType", "milestone");
      formData.append("experienceLevel", randomProjectLevel);
      formData.append("duration", randomJobDuration);
      formData.append("freelancerType", randomFreelancerType);
      formData.append("languageProficiency", randomEnglishLevel);
      formData.append("jobType", randomProjectLocationType);
      formData.append("preferredExperience", randomYearsofExperiencePreferred);
      formData.append("startDate", formattedStartDate);
      formData.append("deadline", formattedDeadlineDate);
      formData.append("expireDate", formattedExpireDate);
      formData.append("approxBudget", randomWorkPrice);
      formData.append("fixedPayableAmount", "");
      formData.append("fixedPaymentType", "");
      formData.append("categories", randomJobCategories);
      formData.append("language", "English");
      formData.append("details", randomJobDetails);
      formData.append("requiredSkills", '["HTML","CSS"]');
      formData.append("location", randomJobLocation);

      formData.append("file", userSelectedFile);
      formData.append("numberOfOpening", "1");
      formData.append("paymentType", "full");
      formData.append("partialAmount", "0");
      // formData.append("attachMent", true);
      // formData.append("projectMileStonePayment", true);

      const respstep3 = await axiousPublic.post(`/jobs`, formData, config11);
      console.log(respstep3.data);
      alert("Client Job Posted Successfully !!!");

      window.location.reload();
    } catch (error) {
      console.error(error.message);
      toast.error("Data not Submitted Try Again !!!"); // Show error toast
    } finally {
      setLoading(false); // Set loading state to false when response is received
    }
  };
  return (
    <>
      <Header />

      <div className="container">
        <Toaster position="top-center" reverseOrder={false} />
        {loading ? (
          <div className="loader-wrapper">
            <Loader
              type="spinner-circle"
              bgColor={color}
              color={color}
              title={"Processing Your Request"}
              size={100}
            />
          </div>
        ) : (
          <form onSubmit={formSubmit}>
            <div className="column jumbotron box8">
              <h4 className=" text-info mb-5 texth4">
                JobOpenings - Post Job Opening by Existing Client with
                MilestoneProject
              </h4>

              <div className="form-group row">
                <label htmlFor="email" className="col-sm-5 col-form-label">
                  Client Email ID
                </label>
                <div className="col-sm-7">
                  <input
                    type="Email"
                    name="email"
                    id="email"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    required="true"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="password" className="col-sm-5 col-form-label">
                  Password
                </label>
                <div className="col-sm-7">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    required="true"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="title" className="col-sm-5 col-form-label">
                  Job Title
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    className="form-control"
                    placeholder=""
                    value={randomJobTitle}
                    onChange={(event) => {
                      setJobTitle(event.target.value);
                      userrJobTitleChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="experienceLevel"
                  className="col-sm-5 col-form-label"
                >
                  Project Level
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="experienceLevel"
                    id="experienceLevel"
                    className="form-control"
                    placeholder=""
                    value={randomProjectLevel}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="duration" className="col-sm-5 col-form-label">
                  Job Duration
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="duration"
                    id="duration"
                    className="form-control"
                    placeholder=""
                    value={randomJobDuration}
                    onChange={(event) => {
                      setJobDuration(event.target.value);
                      userJobDurationChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="freelancerType"
                  className="col-sm-5 col-form-label"
                >
                  Freelancer Type
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="freelancerType"
                    id="freelancerType"
                    className="form-control"
                    placeholder=""
                    value={randomFreelancerType}
                    onChange={(event) => {
                      setFreelancerType(event.target.value);
                      userFreelancerTypeChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="languageProficiency"
                  className="col-sm-5 col-form-label"
                >
                  English Level
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="languageProficiency"
                    id="languageProficiency"
                    className="form-control"
                    placeholder=""
                    value={randomEnglishLevel}
                    onChange={(event) => {
                      setEnglishLevel(event.target.value);
                      userEnglishLevelChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="jobType" className="col-sm-5 col-form-label">
                  Project Location Type
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="jobType"
                    id="jobType"
                    className="form-control"
                    placeholder=""
                    value={randomProjectLocationType}
                    onChange={(event) => {
                      setProjectLocationType(event.target.value);
                      userProjectLocationTypeChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="preferredExperience"
                  className="col-sm-5 col-form-label"
                >
                  Years of Experience Preferred
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="preferredExperience"
                    id="preferredExperience"
                    className="form-control"
                    placeholder=""
                    value={randomYearsofExperiencePreferred}
                    onChange={(event) => {
                      setYearsOfExperiencePreferred(event.target.value);
                      userYearsofExperiencePreferredChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="startDate" className="col-sm-5 col-form-label">
                  Project Start Date
                </label>
                <div className="col-sm-7">
                  <input
                    type="date"
                    name="startDate"
                    id="startDate"
                    className="form-control"
                    placeholder=""
                    value={randomprojectStartDate}
                    onChange={(event) => {
                      setProjectStartDate(event.target.value);
                      usersetProjectStartDate(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="deadline" className="col-sm-5 col-form-label">
                  Project Deadline Date
                </label>
                <div className="col-sm-7">
                  <input
                    type="date"
                    name="deadline"
                    id="deadline"
                    className="form-control"
                    placeholder=""
                    value={randomprojectDeadlineDate}
                    onChange={(event) => {
                      setProjectDeadLineDate(event.target.value);
                      usersetProjectDeadlineDate(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="expireDate" className="col-sm-5 col-form-label">
                  Project Expire Date
                </label>
                <div className="col-sm-7">
                  <input
                    type="date"
                    name="expireDate"
                    id="expireDate"
                    className="form-control"
                    placeholder=""
                    value={randomprojectExpireDate}
                    onChange={(event) => {
                      setProjectExpireDate(event.target.value);
                      usersetProjectExpireDate(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="approxBudget"
                  className="col-sm-5 col-form-label"
                >
                  Work Price ($)
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="approxBudget"
                    id="approxBudget"
                    className="form-control"
                    placeholder=""
                    value={randomWorkPrice}
                    onChange={(event) => {
                      setWorkPrice(event.target.value);
                      userrWorkPriceChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="categories" className="col-sm-5 col-form-label">
                  Job Categories
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="categories"
                    id="categories"
                    className="form-control"
                    placeholder=""
                    value={randomJobCategories}
                    onChange={(event) => {
                      setJobCategories(event.target.value);
                      userJobCategoriesChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="language" className="col-sm-5 col-form-label">
                  Language
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="language"
                    id="language"
                    className="form-control"
                    placeholder=""
                    onChange={(event) => setLanguage(event.target.value)}
                    value="English"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="details" className="col-sm-5 col-form-label">
                  Job Details
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="details"
                    id="details"
                    className="form-control"
                    placeholder=""
                    value={randomJobDetails}
                    onChange={(event) => {
                      setJobDetails(event.target.value);
                      userJobDetailsChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="requiredSkills"
                  className="col-sm-5 col-form-label"
                >
                  Required Skills
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="requiredSkills"
                    id="requiredSkills"
                    className="form-control"
                    placeholder=""
                    onChange={(event) => setrequiredSkills(event.target.value)}
                    value="html"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="location" className="col-sm-5 col-form-label">
                  Location
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="location"
                    id="location"
                    className="form-control"
                    placeholder=""
                    value={randomJobLocation}
                    onChange={(event) => {
                      setLocation(event.target.value);
                      userJobLocationChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="file" className="col-sm-5 col-form-label">
                  Upload Project File
                </label>
                <div className="col-sm-7">
                  <input
                    type="file"
                    id=" file"
                    name="file"
                    accept="image/*"
                    className="form-control"
                    placeholder=""
                    onChange={(e) => setUserSelectedFile(e.target.files[0])}
                    required="true"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="numberOfOpening"
                  className="col-sm-5 col-form-label"
                >
                  Number Of Opening
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    name="numberOfOpening"
                    id="numberOfOpening"
                    className="form-control"
                    placeholder=""
                    value={6}
                  />
                </div>
              </div>

              <br />

              <div className=" form-group mb-0">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
              <br />
              <br />
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default PostJobOpeningMilestoneProject;
